import { NextLink } from 'components/NextLink';
import type { Maybe } from 'server/repository/hygraph/generated/graphqlTypes';
import { LocalizationService } from 'service/localization/localizationService';
import { DictionaryService } from 'service/dictionary/dictionaryService';
import type { PathTranslations } from 'service/localization/getPathTranslations';
import type { Dictionary, DictionaryItem } from 'service/dictionary/dictionaryService';

export function mapGenericAuthorableGridCards(
  cmsItems: any[],
  copyDictionary: Maybe<Dictionary>,
  pathTranslations: PathTranslations,
  recipeCopy: DictionaryItem[],
) {
  const copyDict: Record<string, string> = DictionaryService.mapDictionaryToRecords(copyDictionary);
  const difficultMapping = {
    0: copyDict.easy,
    1: copyDict.intermediate,
    2: copyDict.tough,
  };
  const linkText = DictionaryService.getCopy('see_recipe', recipeCopy);
  const videoButtonText = DictionaryService.getCopy('see_video', recipeCopy);
  const allItems = cmsItems.reduce((cards: any[], itm: any) => {
    if (itm.__typename === 'AuthorableContentCard') {
      return [
        ...cards,
        {
          type: 'authorable',
          title: itm.name,
          subtitle: itm.subtitle,
          copy: itm.copy,
          videoId: itm.videoId,
          videoButtonText,
          image: itm.image?.asset?.[0],
          link: itm?.link && {
            url: LocalizationService.getLocalizedLinkPath({
              item: itm.link,
              pathTranslations,
            }),
            text: itm.link.displayName,
            openInNewTab: itm.link.openInNewTab ?? false,
          },
        },
      ];
    }
    if (itm.__typename === 'FeaturedContentReference') {
      if (itm.recipeData?.Id) {
        const totalPreparationTime =
          itm.recipeData?.TotalTimeInMinutes || itm.recipeData?.PreparationTimeInMinutes;
        return [
          ...cards,
          {
            type: 'recipe',
            data: {
              id: itm?.recipeData?.Id,
              title: itm?.recipeData?.Title,
              description: itm?.recipeData?.ShortDescription,
              categoryId: itm?.recipeData?.Category.Id,
              category: itm?.recipeData?.Category.Title,
              images: {
                hero: itm?.recipeData?.Images?.[0]?.Formats?.[1]?.Url ?? '',
              },
              information: {
                preparationTime: itm?.recipeData?.PreparationTimeInMinutes
                  ? `${itm?.recipeData?.PreparationTimeInMinutes} Min`
                  : null,
                rating: itm.recipeData.rating || 0.0,
                difficulty: itm?.recipeData?.Difficulty,
                totalTime: totalPreparationTime ? `${totalPreparationTime} Min` : null,
              },
            },
            showInfo: true,
            link: {
              url: `${pathTranslations.recipe}${itm.recipeData.slug}`,
              displayName: linkText || 'See Recipe',
            },
            cardVariation: 'recipe',
            copyDictionary: difficultMapping,
            LinkComponent: NextLink,
          },
        ];
      }
      if (itm.tipsAndTricksData?.Id) {
        return [
          ...cards,
          {
            type: 'tips',
            data: {
              id: itm.tipsAndTricksData?.Id,
              title: itm.tipsAndTricksData?.Title,
              description: itm.tipsAndTricksData?.ShortDescription,
              categoryId: itm.tipsAndTricksData?.Category.Id,
              category: itm.tipsAndTricksData?.Category.Title,
              images: {
                hero: itm.tipsAndTricksData?.Images?.[0]?.Formats?.[0]?.Url,
              },
              information: {
                preparationTime: itm.tipsAndTricksData?.PreparationTimeInMinutesSummaryText,
                rating: itm.tipsAndTricksData?.rating,
                difficulty: itm.tipsAndTricksData?.Difficulty,
                totalTime:
                  itm.tipsAndTricksData?.TotalTimeInMinutesSummaryText ||
                  itm.tipsAndTricksData?.PreparationTimeInMinutesSummaryText,
              },
            },
            showInfo: true,
            link: {
              url: `${pathTranslations.tipAndTrick}${itm.tipsAndTricksData.slug}`,
              displayName: copyDict?.see_recipe_details || 'See Recipe',
            },
            cardVariation: 'recipe',
            copyDictionary: difficultMapping,
            LinkComponent: NextLink,
          },
        ];
      }
      if (itm.articlePage?.slug) {
        return [
          ...cards,
          {
            type: 'article',
            data: {
              id: itm.articlePage?.id,
              title: itm.articlePage?.title,
              description: '',
              categoryId: '',
              category: 'article',
              image: {},
              video: '',
            },
            cardVariation: 'article',
            link: {
              url: LocalizationService.getLocalizedLinkPath({
                item: itm.articlePage?.link,
                pathTranslations,
              }),
              displayName: 'Read more',
            },
            LinkComponent: NextLink,
          },
        ];
      }
    }
    return cards;
  }, []);
  return allItems;
}
